/*------------------------------------*\
    RESET
\*------------------------------------*/


/* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0b1 | 201101 
    NOTE:WORK IN PROGRESS
    USE WITH CAUTION AND TEST WITH ABANDON */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ’’;
  content: none;
}

/* remember to define visible focus styles! 
:focus{
    outline:?????;
} */


/* remember to highlight inserts somehow! */

ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------*\
    $FONTS
\*------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700|Oswald:400,700');

/*------------------------------------*\
    $COLORS
\*------------------------------------*/

$light-green: #b6be9c;
$dark-green: #5c7457;
$transparent-light-green: rgba(182,
190,
156,
.50);
//$transparent-dark-green: rgba(92,116, 87, .75);
$transparent-dark-green: rgba(92,
116,
87,
.50);
$transparent-dark-grey: rgba(0,
0,
0,
.25);
$light-blue: #8aa2a9;
$light-grey: #797a79;

/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

@mixin centerText80 {
  margin: 0 auto;
  width: 80%;
}
@mixin centerText90 {
  margin: 0 auto;
  width: 90%;
}
@mixin oswald {
  font-family: 'Oswald', sans-serif;
}
@mixin heebo {
  font-family: 'Heebo', sans-serif;
}

/*------------------------------------*\
    $MAIN STYLES
\*------------------------------------*/

body {
  font-family: 'heebo', 'oswald', helvetica, sans-serif;
  font-size: 12px;
  color: white;
  box-sizing: border-box;
  //background-color: #868C71;
  a {
    color: $dark-green;
  }
  p {
    margin: 10px 0;
    font-size: 1.15em;
  }
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}

/*------------------------------------*\
    $MAIN MENU STYLES
\*------------------------------------*/

.mainMenu {
  @include centerText90;
  ul {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    li {
      position: relative;
      padding: 10px 0;
      background-color: rgba(92, 116, 87, 1);
      a {
        font-size: 1.45em;
        display: block;
        color: white;
        margin: 0 10px;
        text-decoration: none;
        text-transform: uppercase;
        @include heebo;
        transition: all .3s ease;
        padding: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      &:hover {
        .sub-menu {
          display: flex;
        }
      }
    }
    .sub-menu {
      display: none;
      flex-direction: column;
      font-size: .75em;
      position: absolute;
      padding: 0;
      min-width: 500px;
      padding-top: 10px;
      li {
        padding: 0;
        color: $dark-green;
        width: 75%;
      }
    }
  }
}

/*------------------------------------*\
    $FRONT PAGE STYLES
\*------------------------------------*/

.heroImg-Container {
  height: 20vw;
  min-height: 275px;
  background-size: cover;
  background-position: center;
  .welcomeText-Container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    h1 {
      align-items: center;
      background-color: $transparent-dark-green;
      font-size: 5em;
      padding: 25px;
      text-transform: uppercase;
      @include oswald;
      letter-spacing: 2px;
      color: white;
      margin-bottom: 50px;
    }
  }
}
.triangle {
  height: 0;
  width: 0;
  border: 30px solid transparent;
  border-bottom-color: $light-green;
}
.allContent-Container {
  background-color: $dark-green;
  .introStatement-Container {
    background-color: $light-green;
    padding: 10px 0;
    .introStatement {
      background-color: #868C71;
      padding: 10px 0;
      text-align: center;
      p {
        @include centerText80;
        color: white;
        padding: 5px 0;
        line-height: 1.25;
        font-size: 1.15em;
      }
    }
  }
  .listings-Container {
    display: flex;
    justify-content: flex-start;
    //        flex-wrap: wrap-reverse;
    flex-wrap: wrap;
    //overflow:hidden;
    .individualListing {
      width: calc(20% - 10px);
      margin: 2.5px 5px;
      text-align: center;
      a {
        height: 15vh;
//        min-height: 200px;
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //				background-color: $transparent-dark-grey;
        transition: all .3s ease;
        &:hover {
          background-color: $transparent-light-green;
        }
        .propertyText {
          padding: 0 20px;
          font-size: 1.25em;
        }
        h2 {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
    .propertyText {
      padding: 10px 20px;
      font-size: 1.25em;
    }
    h2 {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

/*------------------------------------*\
    $SUB PAGE STYLES
\*------------------------------------*/

.subPage-Container {
  display: flex;
  background-color: #eeeeee;
  button {
    color: black;
    background-color: $dark-green;
    border: none;
    display: none;
  }
  .sideBar-Container {
    background-color: #b6be9c;
    min-width: 20%;
    //min-height: 85vh;
    >h2 {
      text-align: center;
      padding: 10px 0;
      font-size: 1.25vw;
      font-weight: 700;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .individualListing {
      background: #5c7457;
      padding: 2.5px;
      a {
        min-height: 50px;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-decoration: none;
        font-family: 'Oswald', sans-serif;
        background-color: rgba(0, 0, 0, 0.25);
        transition: all .3s ease;
        &:hover {
          background-color: $transparent-dark-green;
        }
        .propertyText {
          text-align: center;
          font-size: 1.25em;
          h2 {
            font-size: 1.25vw;
            font-weight: 700;
            margin-bottom: 5px;
            text-transform: uppercase;
            line-height: 1.25;
          }
          span {
            font-size: 1.25em;
          }
        }
      }
    }
  }
  .mainContent-Container {
    width: 100%;
    .heroImg-Container {
      .welcomeText-Container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        h1 {
          align-items: center;
          background-color: $dark-green;
          font-size: 3.5em;
          padding: 10px 15px;
          text-transform: uppercase;
          @include oswald;
          letter-spacing: 2px;
          color: white;
          text-align: center;
        }
      }
    }
    main {
      display: block;
      width: 90%;
      max-width:1080px;
      margin: 0 auto;
      //			max-width: 750px;
      color: black;
      line-height: 1.15;
      font-size: 1.25em;
      ol {
        li {
          margin-bottom: 10px;
        }
      }
      .propertyImages-Container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        
        .images-Container {
          width: calc(33% - 5px);
          min-width: 350px;
          height: auto;
//        flex-grow:1;
          .slides {
            display: flex;
            flex-wrap: wrap;
            .slide {
              width: calc(25% - 4px);
              margin: 0 2px;
              &:first-of-type {
                width: 100%;
                img {
                  height: 250px;
                }
              }
              img {
                width: 100%;
                height: 75px;
              }
            }
          }
        }
        iframe {
          width: calc(33% - 5px);
          height: 250px;
          flex-grow:1;
        }
      }
      h2 {
        @include oswald;
        font-size: 2em;
        padding: 20px 0;
        text-align: center;
        margin-bottom: 40px;
        border-bottom: 2px solid $dark-green;
        text-transform: uppercase;
        color: $dark-green;
      }
      .quickLink-Container {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        //				a{
        //					margin:0px 5px;
        //					background-color: $dark-green;
        //					padding: 5px 10px;
        //					color: white;
        //					text-decoration: none;
        //					text-transform: uppercase;
        //				}
      }
    }
  }
}

/*------------------------------------*\
    $PROPERTY DETAIL STYLES
\*------------------------------------*/

main {
  section {
    margin: 5px 0;
    .detail-Container {
      margin: 20px 0;
      p {
        margin: 20px 0;
      }
    }
    h3 {
      @include oswald;
      font-size: 2em;
      font-weight: 700;
      text-transform: uppercase;
      color: $dark-green;
    }
    h4 {
      @include oswald;
      font-size: 1.5em;
      color: $dark-green;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
  .pageLinks-Container,
  .quickLink-Container {
    //		  margin: 30px 0;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: .75em;
    a {
      background-color: $dark-green;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      min-width: 100px;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      transition: all .3s ease;
      font-size: 1.45em;
      &:hover {
        background-color: $light-green;
      }
    }
  }
  .propertyInfo-Container {
    display: flex;
    justify-content: flex-start;
    .column {
      margin: 0 20px;
      width: 49%;
      ul {
        list-style: disc;
        list-style-position: inside;
      }
      div {
        font-size: 1.33em;
        span {
          color: $dark-green;
          text-transform: uppercase;
          font-weight: 700;
          min-width: 80px;
          display: inline-block;
        }
      }
    }
  }
  .propertyHelpfulInfo-Container {
    display: flex;
    flex-wrap: wrap;
    .detail-Container {
      margin: 20px;
      max-width: 100%;
    }
  }
  .contactInfo-Container {
    justify-content: center;
  }
}

/*------------------------------------*\
    $CONTACT FORM STYLES
\*------------------------------------*/

.ninja-forms-form-wrap * {
  overflow: hidden;
}
.nf-form-cont .nf-field-element textarea {
  height: 125px;
}
.nf-field-element input[type='checkbox'] {
  width: auto;
}
.nf-response-msg {
  margin: 20px 0;
  font-size: .75em;
  font-style: italic;
}
.nf-form-cont {
  margin: 10px auto;
  max-width: 725px;
  .nf-before-form-content {
    margin: 10px 0;
  }
  nf-fields-wrap {
    display: flex;
    flex-wrap: wrap;
    nf-field {
      margin-right: 10px;
      flex-grow: 1;
      &:last-of-type {
        width: 100%;
      }
      input,
      select,
      textarea {
        border: 3px solid $light-blue;
        padding: 8px;
      }
      input[type=button] {
        color: white;
        text-transform: uppercase;
        background-color: $light-blue;
        border: 3px solid white;
        transition: all .3s ease;
        &:hover {
          border: 3px solid $light-blue;
          background-color: white;
          color: $light-blue;
          cursor: pointer;
        }
      }
      p {
        width: 100%;
      }
    }
  }
  .nf-error-msg {
    font-size: .75em;
  }
}
.nf-field-label label {
  min-width: 150px;
}

/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

footer {
  background-color: #868C71;
  .footerContent-Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .leftSideContent-Container {
      display: flex;
      align-items: center;
      .logo-Container {
        img {
          height: 50px;
          width: auto;
        }
      }
      .secondaryMenu {
        margin-left: 10px;
        a {
          @include oswald;
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          transition: all .3s ease;
          &:hover {
            color: $light-green;
          }
        }
      }
    }
    .rightSideContent-Container {
      text-align: right;
      #menu-footer-menu-right {
        margin: 5px 0;
        display: flex;
        li {
          padding: 0 10px;
          &:first-of-type {
            border-right: 1px solid white;
          }
          a {
            color: white;
            text-decoration: none;
            transition: all .3s ease;
            &:hover {
              color: $light-green;
            }
          }
        }
      }
    }
  }
}
//
////.lightbox .lb-container>.lb-image{
////  width:auto;
////  max-height:750px;
//}

/*------------------------------------*\
    $MOBILE STYLES
\*------------------------------------*/

@media (min-width:1500px) {
  .heroImg-Container .welcomeText-Container h1 {
    font-size: 5em;
  }
}
@media (max-width:1000px) {
  body {
    font-size: 12px;
  }
  .allContent-Container .listings-Container .individualListing {
    width: calc(33% - 10px);
  }
  .subPage-Container .mainContent-Container main .propertyImages-Container{
    flex-direction: column;
    align-items:center;
    .images-Container{
      flex-grow: 1;
    }
    iframe{
      width:100%;
    }
  }
  .subPage-Container .sideBar-Container {
    .individualListing {
      a .propertyText {
        h2{
          font-size:1.75vw;
        }
      }
    }
  }
  .rightSideContent-Container{
    font-size: 1.5em;
  }
}
@media (max-width: 750px) {
  .subPage-Container {
    .sideBar-Container {
      display: none;
    }
  }
}
@media (max-width: 640px) {
  main .propertyInfo-Container {
    flex-wrap: wrap;
    .column {
      margin: .5em 0;
      width: 100%;
    }
  }
  .mainMenu ul li a {
    font-size: 1em;
  }
  .allContent-Container .listings-Container {
    //		flex-direction: column;
    flex-wrap: wrap;
  }
  .allContent-Container .listings-Container .individualListing {
    width: 100%;
    max-width: none;
  }
  .allContent-Container .listings-Container .individualListing a .propertyText {
    font-size: 1.25em;
  }
  .heroImg-Container .welcomeText-Container h1 {
    font-size: 10vw;
    padding: 10px;
  }
  .subPage-Container {
    .mainContent-Container {
      .heroImg-Container .welcomeText-Container h1 {
        font-size: 5vw;
        text-align: center;
      }
      main {
        .pageLinks-Container {
          a {
            width: 100%;
          }
        }
        .propertyImages-Container {
          flex-direction: column;
          .images-Container {
            margin: 20px 0;
            width: 100%;
          }
        }
      }
    }
    .sideBar-Container {
      .individualListing {
        padding: 0;
      }
    }
  }
  footer .footerContent-Container {
    flex-direction: column;
    padding: 20px 0;
    .rightSideContent-Container {
      margin: 10px 0;
      font-size: 5vw;
    }
  }
}
