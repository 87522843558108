@charset "UTF-8";
/*------------------------------------*    RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0b1 | 201101 
    NOTE:WORK IN PROGRESS
    USE WITH CAUTION AND TEST WITH ABANDON */
@import url("https://fonts.googleapis.com/css?family=Heebo:400,700|Oswald:400,700");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ’’;
  content: none; }

/* remember to define visible focus styles! 
:focus{
    outline:?????;
} */
/* remember to highlight inserts somehow! */
ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*------------------------------------*    $FONTS
\*------------------------------------*/
/*------------------------------------*    $COLORS
\*------------------------------------*/
/*------------------------------------*    $MIXINS
\*------------------------------------*/
/*------------------------------------*    $MAIN STYLES
\*------------------------------------*/
body {
  font-family: 'heebo', 'oswald', helvetica, sans-serif;
  font-size: 12px;
  color: white;
  box-sizing: border-box; }
  body a {
    color: #5c7457; }
  body p {
    margin: 10px 0;
    font-size: 1.15em; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

/*------------------------------------*    $MAIN MENU STYLES
\*------------------------------------*/
.mainMenu {
  margin: 0 auto;
  width: 90%; }
  .mainMenu ul {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0; }
    .mainMenu ul li {
      position: relative;
      padding: 10px 0;
      background-color: #5c7457; }
      .mainMenu ul li a {
        font-size: 1.45em;
        display: block;
        color: white;
        margin: 0 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Heebo', sans-serif;
        transition: all .3s ease;
        padding: 10px; }
        .mainMenu ul li a:hover {
          text-decoration: underline; }
      .mainMenu ul li:hover .sub-menu {
        display: flex; }
    .mainMenu ul .sub-menu {
      display: none;
      flex-direction: column;
      font-size: .75em;
      position: absolute;
      padding: 0;
      min-width: 500px;
      padding-top: 10px; }
      .mainMenu ul .sub-menu li {
        padding: 0;
        color: #5c7457;
        width: 75%; }

/*------------------------------------*    $FRONT PAGE STYLES
\*------------------------------------*/
.heroImg-Container {
  height: 20vw;
  min-height: 275px;
  background-size: cover;
  background-position: center; }
  .heroImg-Container .welcomeText-Container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center; }
    .heroImg-Container .welcomeText-Container h1 {
      align-items: center;
      background-color: rgba(92, 116, 87, 0.5);
      font-size: 5em;
      padding: 25px;
      text-transform: uppercase;
      font-family: 'Oswald', sans-serif;
      letter-spacing: 2px;
      color: white;
      margin-bottom: 50px; }

.triangle {
  height: 0;
  width: 0;
  border: 30px solid transparent;
  border-bottom-color: #b6be9c; }

.allContent-Container {
  background-color: #5c7457; }
  .allContent-Container .introStatement-Container {
    background-color: #b6be9c;
    padding: 10px 0; }
    .allContent-Container .introStatement-Container .introStatement {
      background-color: #868C71;
      padding: 10px 0;
      text-align: center; }
      .allContent-Container .introStatement-Container .introStatement p {
        margin: 0 auto;
        width: 80%;
        color: white;
        padding: 5px 0;
        line-height: 1.25;
        font-size: 1.15em; }
  .allContent-Container .listings-Container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .allContent-Container .listings-Container .individualListing {
      width: calc(20% - 10px);
      margin: 2.5px 5px;
      text-align: center; }
      .allContent-Container .listings-Container .individualListing a {
        height: 15vh;
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all .3s ease; }
        .allContent-Container .listings-Container .individualListing a:hover {
          background-color: rgba(182, 190, 156, 0.5); }
        .allContent-Container .listings-Container .individualListing a .propertyText {
          padding: 0 20px;
          font-size: 1.25em; }
        .allContent-Container .listings-Container .individualListing a h2 {
          font-weight: 700;
          text-transform: uppercase; }
    .allContent-Container .listings-Container .propertyText {
      padding: 10px 20px;
      font-size: 1.25em; }
    .allContent-Container .listings-Container h2 {
      font-weight: 700;
      text-transform: uppercase; }

/*------------------------------------*    $SUB PAGE STYLES
\*------------------------------------*/
.subPage-Container {
  display: flex;
  background-color: #eeeeee; }
  .subPage-Container button {
    color: black;
    background-color: #5c7457;
    border: none;
    display: none; }
  .subPage-Container .sideBar-Container {
    background-color: #b6be9c;
    min-width: 20%; }
    .subPage-Container .sideBar-Container > h2 {
      text-align: center;
      padding: 10px 0;
      font-size: 1.25vw;
      font-weight: 700;
      margin-bottom: 5px;
      text-transform: uppercase; }
    .subPage-Container .sideBar-Container .individualListing {
      background: #5c7457;
      padding: 2.5px; }
      .subPage-Container .sideBar-Container .individualListing a {
        min-height: 50px;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-decoration: none;
        font-family: 'Oswald', sans-serif;
        background-color: rgba(0, 0, 0, 0.25);
        transition: all .3s ease; }
        .subPage-Container .sideBar-Container .individualListing a:hover {
          background-color: rgba(92, 116, 87, 0.5); }
        .subPage-Container .sideBar-Container .individualListing a .propertyText {
          text-align: center;
          font-size: 1.25em; }
          .subPage-Container .sideBar-Container .individualListing a .propertyText h2 {
            font-size: 1.25vw;
            font-weight: 700;
            margin-bottom: 5px;
            text-transform: uppercase;
            line-height: 1.25; }
          .subPage-Container .sideBar-Container .individualListing a .propertyText span {
            font-size: 1.25em; }
  .subPage-Container .mainContent-Container {
    width: 100%; }
    .subPage-Container .mainContent-Container .heroImg-Container .welcomeText-Container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: center; }
      .subPage-Container .mainContent-Container .heroImg-Container .welcomeText-Container h1 {
        align-items: center;
        background-color: #5c7457;
        font-size: 3.5em;
        padding: 10px 15px;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 2px;
        color: white;
        text-align: center; }
    .subPage-Container .mainContent-Container main {
      display: block;
      width: 90%;
      max-width: 1080px;
      margin: 0 auto;
      color: black;
      line-height: 1.15;
      font-size: 1.25em; }
      .subPage-Container .mainContent-Container main ol li {
        margin-bottom: 10px; }
      .subPage-Container .mainContent-Container main .propertyImages-Container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap; }
        .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container {
          width: calc(33% - 5px);
          min-width: 350px;
          height: auto; }
          .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container .slides {
            display: flex;
            flex-wrap: wrap; }
            .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container .slides .slide {
              width: calc(25% - 4px);
              margin: 0 2px; }
              .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container .slides .slide:first-of-type {
                width: 100%; }
                .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container .slides .slide:first-of-type img {
                  height: 250px; }
              .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container .slides .slide img {
                width: 100%;
                height: 75px; }
        .subPage-Container .mainContent-Container main .propertyImages-Container iframe {
          width: calc(33% - 5px);
          height: 250px;
          flex-grow: 1; }
      .subPage-Container .mainContent-Container main h2 {
        font-family: 'Oswald', sans-serif;
        font-size: 2em;
        padding: 20px 0;
        text-align: center;
        margin-bottom: 40px;
        border-bottom: 2px solid #5c7457;
        text-transform: uppercase;
        color: #5c7457; }
      .subPage-Container .mainContent-Container main .quickLink-Container {
        display: flex;
        justify-content: center;
        padding: 20px 0; }

/*------------------------------------*    $PROPERTY DETAIL STYLES
\*------------------------------------*/
main section {
  margin: 5px 0; }
  main section .detail-Container {
    margin: 20px 0; }
    main section .detail-Container p {
      margin: 20px 0; }
  main section h3 {
    font-family: 'Oswald', sans-serif;
    font-size: 2em;
    font-weight: 700;
    text-transform: uppercase;
    color: #5c7457; }
  main section h4 {
    font-family: 'Oswald', sans-serif;
    font-size: 1.5em;
    color: #5c7457;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px; }

main .pageLinks-Container,
main .quickLink-Container {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: .75em; }
  main .pageLinks-Container a,
  main .quickLink-Container a {
    background-color: #5c7457;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 100px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    transition: all .3s ease;
    font-size: 1.45em; }
    main .pageLinks-Container a:hover,
    main .quickLink-Container a:hover {
      background-color: #b6be9c; }

main .propertyInfo-Container {
  display: flex;
  justify-content: flex-start; }
  main .propertyInfo-Container .column {
    margin: 0 20px;
    width: 49%; }
    main .propertyInfo-Container .column ul {
      list-style: disc;
      list-style-position: inside; }
    main .propertyInfo-Container .column div {
      font-size: 1.33em; }
      main .propertyInfo-Container .column div span {
        color: #5c7457;
        text-transform: uppercase;
        font-weight: 700;
        min-width: 80px;
        display: inline-block; }

main .propertyHelpfulInfo-Container {
  display: flex;
  flex-wrap: wrap; }
  main .propertyHelpfulInfo-Container .detail-Container {
    margin: 20px;
    max-width: 100%; }

main .contactInfo-Container {
  justify-content: center; }

/*------------------------------------*    $CONTACT FORM STYLES
\*------------------------------------*/
.ninja-forms-form-wrap * {
  overflow: hidden; }

.nf-form-cont .nf-field-element textarea {
  height: 125px; }

.nf-field-element input[type='checkbox'] {
  width: auto; }

.nf-response-msg {
  margin: 20px 0;
  font-size: .75em;
  font-style: italic; }

.nf-form-cont {
  margin: 10px auto;
  max-width: 725px; }
  .nf-form-cont .nf-before-form-content {
    margin: 10px 0; }
  .nf-form-cont nf-fields-wrap {
    display: flex;
    flex-wrap: wrap; }
    .nf-form-cont nf-fields-wrap nf-field {
      margin-right: 10px;
      flex-grow: 1; }
      .nf-form-cont nf-fields-wrap nf-field:last-of-type {
        width: 100%; }
      .nf-form-cont nf-fields-wrap nf-field input,
      .nf-form-cont nf-fields-wrap nf-field select,
      .nf-form-cont nf-fields-wrap nf-field textarea {
        border: 3px solid #8aa2a9;
        padding: 8px; }
      .nf-form-cont nf-fields-wrap nf-field input[type=button] {
        color: white;
        text-transform: uppercase;
        background-color: #8aa2a9;
        border: 3px solid white;
        transition: all .3s ease; }
        .nf-form-cont nf-fields-wrap nf-field input[type=button]:hover {
          border: 3px solid #8aa2a9;
          background-color: white;
          color: #8aa2a9;
          cursor: pointer; }
      .nf-form-cont nf-fields-wrap nf-field p {
        width: 100%; }
  .nf-form-cont .nf-error-msg {
    font-size: .75em; }

.nf-field-label label {
  min-width: 150px; }

/*------------------------------------*    $FOOTER
\*------------------------------------*/
footer {
  background-color: #868C71; }
  footer .footerContent-Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px; }
    footer .footerContent-Container .leftSideContent-Container {
      display: flex;
      align-items: center; }
      footer .footerContent-Container .leftSideContent-Container .logo-Container img {
        height: 50px;
        width: auto; }
      footer .footerContent-Container .leftSideContent-Container .secondaryMenu {
        margin-left: 10px; }
        footer .footerContent-Container .leftSideContent-Container .secondaryMenu a {
          font-family: 'Oswald', sans-serif;
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          transition: all .3s ease; }
          footer .footerContent-Container .leftSideContent-Container .secondaryMenu a:hover {
            color: #b6be9c; }
    footer .footerContent-Container .rightSideContent-Container {
      text-align: right; }
      footer .footerContent-Container .rightSideContent-Container #menu-footer-menu-right {
        margin: 5px 0;
        display: flex; }
        footer .footerContent-Container .rightSideContent-Container #menu-footer-menu-right li {
          padding: 0 10px; }
          footer .footerContent-Container .rightSideContent-Container #menu-footer-menu-right li:first-of-type {
            border-right: 1px solid white; }
          footer .footerContent-Container .rightSideContent-Container #menu-footer-menu-right li a {
            color: white;
            text-decoration: none;
            transition: all .3s ease; }
            footer .footerContent-Container .rightSideContent-Container #menu-footer-menu-right li a:hover {
              color: #b6be9c; }

/*------------------------------------*    $MOBILE STYLES
\*------------------------------------*/
@media (min-width: 1500px) {
  .heroImg-Container .welcomeText-Container h1 {
    font-size: 5em; } }

@media (max-width: 1000px) {
  body {
    font-size: 12px; }
  .allContent-Container .listings-Container .individualListing {
    width: calc(33% - 10px); }
  .subPage-Container .mainContent-Container main .propertyImages-Container {
    flex-direction: column;
    align-items: center; }
    .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container {
      flex-grow: 1; }
    .subPage-Container .mainContent-Container main .propertyImages-Container iframe {
      width: 100%; }
  .subPage-Container .sideBar-Container .individualListing a .propertyText h2 {
    font-size: 1.75vw; }
  .rightSideContent-Container {
    font-size: 1.5em; } }

@media (max-width: 750px) {
  .subPage-Container .sideBar-Container {
    display: none; } }

@media (max-width: 640px) {
  main .propertyInfo-Container {
    flex-wrap: wrap; }
    main .propertyInfo-Container .column {
      margin: .5em 0;
      width: 100%; }
  .mainMenu ul li a {
    font-size: 1em; }
  .allContent-Container .listings-Container {
    flex-wrap: wrap; }
  .allContent-Container .listings-Container .individualListing {
    width: 100%;
    max-width: none; }
  .allContent-Container .listings-Container .individualListing a .propertyText {
    font-size: 1.25em; }
  .heroImg-Container .welcomeText-Container h1 {
    font-size: 10vw;
    padding: 10px; }
  .subPage-Container .mainContent-Container .heroImg-Container .welcomeText-Container h1 {
    font-size: 5vw;
    text-align: center; }
  .subPage-Container .mainContent-Container main .pageLinks-Container a {
    width: 100%; }
  .subPage-Container .mainContent-Container main .propertyImages-Container {
    flex-direction: column; }
    .subPage-Container .mainContent-Container main .propertyImages-Container .images-Container {
      margin: 20px 0;
      width: 100%; }
  .subPage-Container .sideBar-Container .individualListing {
    padding: 0; }
  footer .footerContent-Container {
    flex-direction: column;
    padding: 20px 0; }
    footer .footerContent-Container .rightSideContent-Container {
      margin: 10px 0;
      font-size: 5vw; } }
